import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Logo } from './styles';

function BrandLogo({ slug, width, height = '30px' }) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [slug]);

  return (
    <div>
      {!isError ? (
        <Logo
          src={`/images/brandLogo/${slug}.svg`}
          width={width}
          height={height}
          onError={setIsError}
        />
      ) : (
        <Logo
          src="/images/brandLogo/brand-generic.svg"
          width={width}
          height={height}
          onError={setIsError}
        />
      )}
    </div>
  );
}

BrandLogo.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}.isRequired;

export default BrandLogo;
